<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/discounts/promocodes">
          <b-button variant="primary">
            <span class="align-middle">Back</span>
          </b-button>
        </router-link>
      </div>
    </div>
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Deleted Parts">
        <b-table
          :responsive="true"
          :busy.sync="isBusy"
          :items="items"
          :fields="fields"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>

          <template #cell(ID)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(status)="data">
            {{ data.value === 1 ? 'Enable':'Disable' }}
          </template>
          <template #cell(show_on_website)="data">
            {{ data.value === 1 ? 'Yes':'No' }}
          </template>
          <template #cell(actions)="data">
            <div class="d-flex">
              <b-button
                variant="flat-success"
                class="btn-icon"
                @click="restore(data.item)"
              >
                Restore
              </b-button>

              <b-button
                variant="flat-danger"
                class="btn-icon"
                @click="deletePromocode(data.item)"
              >
                Delete Permanently
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const discountsModule = createNamespacedHelpers('discounts')

export default {
  data() {
    return {
      isBusy: false,
      loader: false,
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'code', label: 'code' },
        { key: 'discount', label: 'discount' },
        { key: 'status', label: 'status' },
        { key: 'type', label: 'type' },
        { key: 'show_on_website', label: 'show_on_website' },
        { key: 'actions', label: 'actions' },
      ],
      items: [],
    }
  },
  async mounted() {
    await this.getPromocodes()
  },
  methods: {
    ...discountsModule.mapActions(['FETCH_ALL_DELETED_PROMOCODE', 'DELETE_PROMOCODE_PERMANENT', 'RESTORE_PROMOCODE']),

    async getPromocodes() {
      try {
        this.isBusy = true
        this.items = await this.FETCH_ALL_DELETED_PROMOCODE()
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },
    async deletePromocode(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to permanently delete this promocode', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.loader = true
              const resp = await this.DELETE_PROMOCODE_PERMANENT(item.id)
              if (resp) {
                this.items = await this.FETCH_ALL_DELETED_PROMOCODE()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Promocode deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'Promocode has been permanently deleted!',
                  },
                })
              }
              this.loader = false
            } catch (error) {
              this.loader = false
              this.items = []
            }
          }
        })
    },
    async restore(item) {
      try {
        this.loader = true
        const resp = await this.RESTORE_PROMOCODE(item.id)
        if (resp) {
          this.items = await this.FETCH_ALL_DELETED_PROMOCODE()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Promocode restored!',
              icon: 'PlusIcon',
              variant: 'success',
              text: 'Promocode has been restored successfully!',
            },
          })
        }
        this.loader = false
      } catch (error) {
        this.loader = false
        this.items = []
      }
    },
  },
}
</script>
